<template>
  <v-lazy
    :options="{
      threshold: .5
    }"
  >
    <v-container>
      <ManagePointHistoryHeader />
      <ManagePointHistorySearch :companies="companies" @search="getPagination" />
      <ManagePointHistoryContent ref="content" :pagination="pagination" />
      <v-sheet class="pa-3 d-flex">
        <v-row>
          <v-col cols="12" md="12" class="text-center">
            <Pagination
              ref="pagination"
              :pagination="pagination"
              :show-num="7"
              :base-url="'/manage/history/'"
              :query="$route.query"
            />
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
  </v-lazy>
</template>

<script>
  import ManagePointHistoryHeader from '@/views/manage/history/Header'
  import ManagePointHistoryContent from '@/views/manage/history/Content'
  import ManagePointHistorySearch from '@/views/manage/history/Search'
  import Pagination from '@/components/core/Pagination'
  export default {
    name: 'PointHistory',
    components: { Pagination, ManagePointHistorySearch, ManagePointHistoryContent, ManagePointHistoryHeader },
    data () {
      return {
        pagination: {
          list: [],
          pageNum: this.$route.params.pageNum,
        },
        companies: [],
      }
    },
    computed: {
      pageNum () {
        return this.$route.params.pageNum
      },
      sdt () {
        return this.$route.query.sdt
      },
      edt () {
        return this.$route.query.edt
      },
      company () {
        return this.$route.query.company
      },
      field () {
        return this.$route.query.field
      },
      keyword () {
        return this.$route.query.keyword
      },
    },
    watch: {
      pageNum () {
        this.getPagination()
      },
    },
    beforeMount () {
      this.getCompanies()
      this.getPagination()
    },
    methods: {
      getPagination (payload) {
        const url = `/manage/history/points`
        const data = {
          pageNum: this.pageNum,
          sdt: payload && payload.sdt ? payload.sdt : this.sdt,
          edt: payload && payload.edt ? payload.edt : this.edt,
          company: payload && payload.company ? payload.company : this.company,
          field: payload && payload.field ? payload.field : this.field,
          keyword: payload && payload.keyword ? payload.keyword : this.keyword,
        }
        this.$axios.get(url, {
          params: data,
        })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      async getCompanies () {
        const url = '/company/company/items'
        await this.$axios.get(url)
          .then(res => {
            if (res && res.data) {
              this.companies = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
    },
  }
</script>

<style scoped>

</style>
