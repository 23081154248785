<template>
  <v-row>
    <v-col cols="12">
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th
                v-for="(col, i) in cols"
                :id="'header_' + i"
                :key="i"
                class="text-subtitle-1 text-center"
              >
                {{ col }}
              </th>
            </tr>
          </thead>
          <tbody v-if="!pagination || pagination.list.length === 0">
            <tr>
              <td :colspan="cols.length">
                <p class="text-body-1 my-1 text-center">
                  조회 된 출금신청 내역이 존재하지 않습니다.
                </p>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="item in pagination.list" :key="item.idx">
              <td class="text-center">
                <span class="text-truncate">
                  <template v-if="item.article">
                    <template v-if="item.article.alias">
                      <v-tooltip bottom>
                        <template #activator="{ attrs, on }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon class="text-caption">
                              mdi-account-plus
                            </v-icon>
                            {{ item.article.alias.username }} ({{ item.article.alias.id }})
                          </span>
                        </template>
                        <span>
                          {{ item.article.users.username }} ({{ item.article.users.id }})
                        </span>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      {{ item.article.users ? item.article.users.username + '(' + item.article.users.id + ')' : '-' }}
                    </template>
                  </template>
                  <template v-else>
                    {{ item.users ? item.users.username + '(' + item.users.id + ')' : '-' }}
                  </template>
                </span>
              </td>
              <td class="text-center">
                <v-tooltip bottom>
                  <template #activator="{ attrs, on }">
                    <span
                      class="text-truncate"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.content ? item.content.length > 20 ? item.content.substring(0, 20) + '..' : item.content : '-' }}
                    </span>
                  </template>
                  <span>
                    {{ item.content ? item.content : '-' }}
                  </span>
                </v-tooltip>
              </td>
              <td class="text-center">
                <span class="text-truncate">
                  {{ item.amount ? item.amount.toLocaleString() : '' }}
                </span>
              </td>
              <td class="text-center">
                <v-btn
                  v-if="item.article"
                  text
                  small
                  :to="`/article/${item.article.idx}`"
                  target="_blank"
                >
                  <v-tooltip bottom>
                    <template #activator="{ attrs, on }">
                      <span
                        class="text-truncate"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ item.article ? item.article.title.length > 10 ? item.article.title.substring(0, 10) + '..' : item.article.title : '-' }}
                      </span>
                    </template>
                    <span>
                      {{ item.article ? item.article.title : '-' }}
                    </span>
                  </v-tooltip>
                  <v-icon class="text-caption">
                    mdi-open-in-new
                  </v-icon>
                </v-btn>
              </td>
              <td class="text-center">
                <span class="text-truncate">
                  {{ item.registerDate | moment('YYYY.MM.DD HH:mm:ss') }}
                </span>
              </td>
              <td class="text-center">
                <span class="text-truncate">
                  {{ item.processAdmin ? item.processAdmin : '-' }}
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ManagePointHistoryContent',
    props: {
      pagination: {},
    },
    data () {
      return {
        cols: ['사용자', '내용', '금액', '게시글', '승인일', '승인자'],
        selected: [],
      }
    },
    computed: {
      checkAll: {
        get () {
          return this.pagination ? (this.selected ? (this.selected.length === this.pagination.list.length) : false) : false
        },
        set (value) {
          const selected = []
          if (value) {
            this.pagination.list.forEach(user => {
              selected.push(user.idx)
            })
            this.selected = selected
          } else {
            this.selected = []
          }
        },
      },
    },
    methods: {
      clearSelected () {
        this.selected = []
      },
    },
  }
</script>

<style scoped>

</style>
